<template>
<div class="custom-width">
    <h3 style="text-align:center">收件信息填写 / 支付</h3>

    <div class="tblup">
        <div class="glotbl">
            <div class="tbTit">
                收件人
            </div>
            <div class="tbInnr">
                <label for="">联系人</label>
                <input type="label" placeholder="请填写您的姓名" v-model="name">
                <!-- {{visitname}} -->
            </div>
            <div class="tbInnr">
                <label for="">手机</label>
                <input type="label" placeholder="请填写收件人手机号码" v-model="phone">
                <!-- {{phone}} -->
            </div>

        </div>
        <div class="glotbl">
            <div class="tbTit">
                地址
            </div>
            <div class="tbInnr">
                <label for="">详细地址</label>
                <input type="label" placeholder="请填写居住地或详细地址" v-model="address">
            </div>
        </div>

    </div>
    <div class="Btmwrp">
        <label></label>
        <button type="default" class="btn" data-url="/pages/orderSucc/orderSucc" @click="sendOrder">去支付</button>
    </div>
</div>
</template>

<script>
let app = null
export default {
    data() {
        return {
            domin: "https://www.visasiv.cn",
            ordersid: 0,
            name: "",
            phone: "",
            item: {},
            mid: 0,
            address: "",
            detailsAdress: "",
            price: 0,
            total: 0,
            Merchant_Name: "",
            Merchant_DeliveryFee: 0,
            order_array: [],
            orderModel: "",
            adrsvalue: ['省份', '城市', '区县'],
            cityPickerValueDefault: [10, 2, 0],
            pickerText: '',
            district: "",
            ordersid: 0,
            addressArr: [],
        }
    },
    created() {
        app = this.$.appContext.app
        
    },
    onLoad(opt) {

        this.ordersid = opt.id
    },

    methods: {

        sendOrder() {
            if (this.name == '') {
                this.$message("请填写收件人")
                return false
            }
            if (this.phone == '') {
                
                this.$message("请填写收件人手机号码")
                return false
            }
            if (this.address == '') {
                this.$message("请填写收件人的地址")
                return false
            }

            this.$message("功能开发中")

        },
        openAddres() {
            this.cityPickerValueDefault = [10, 2, 0]
            this.$refs.simpleAddress.open();
        },
        onConfirm(e) {
            console.log(e)
            this.addressArr = e.labelArr
            this.pickerText = e.labelArr.join(" ");
            this.district = e.labelArr.join(" ")
        },
        adrsChange(evt) {
            let adrs = evt.detail.value
            console.log('the address:', adrs)
            this.adrsvalue = adrs
        }
    }
}
</script>

<style>
page {
    padding-bottom: 70px;
}

.glotbl {
    background-color: #fff;
}

.totalWrp {
    padding: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.totalWrp image {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.swichwrp switch {
    scale: 0.8;
}

.swichwrp {
    padding: 5px 0;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tblup {
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0 0 6px #ccc;
    overflow: hidden;
}

.tbTit,
.tbInnr {
    padding: 0 12px;
    box-sizing: border-box;
}

.tbInnr label {
    font-size: 17px;
}

.tbTit {
    position: relative;
    padding-left: 17px;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    background-color: #f97a0a;
}

.tbTit::before {
    content: "";
    display: block;
    height: 18px;
    width: 3px;
    position: absolute;
    left: 6px;
    top: 5px;
    background-color: #fff;
}

.tbInnr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 3.1;
    border-bottom: 1px solid #e9e9e9;
}

.tbInnr label {
    font-size: 14px;
    width: 80px;
}

.tbInnr input {
    border: none;
    outline: none;
    width: 76%;
    font-size: 16px;
    line-height: 2.1;
    padding-left: 1rem;
    box-sizing: border-box;
}

picker {
    width: 100%;
    font-size: 15px;
    padding-left: 1rem;
    height: 43px;
    box-sizing: border-box;
}

picker .uni-input {
    height: 43px;
    line-height: 43px;
}

.uni-input {
    height: 25px;
    line-height: 25px;
}

.tbInnr labelarea {
    width: 100%;
    margin: 8px 0;
    border-radius: 5px;
    padding: 6px;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.7;
}

.adrsWrp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #333;
}

.adrsWrp label {
    width: 31%;
}

.tb-mod {
    font-size: 12px;
    margin-top: 5px;
}

.tbTits {
    white-space: nowrap;
    width: 50%;
    font-size: 14px;
    overflow: hidden;
}

.tbCount,
.tbTits {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.tbCount {
    justify-content: space-between;

}

.tbCount .ans {
    color: #a0a0a0;
    font-size: 13px;
}

.tbftp {
    align-items: flex-start;
}

.Btmwrp {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 89;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #333;

}

.Btmwrp label {
    margin-left: 12px;
    font-size: 30px;
}

.Btmwrp button {
    color: #fff;
    display: inline-block;
    background-color: #F97A0A;
    margin: 0;
    height: 47px;
    line-height: 47px;
    padding: 0 22px;
    border-radius: 22px;
    font-size: 15px;
}
</style>
